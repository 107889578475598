<template>
  <div class="pd-20">
    <a-page-header class="pd-0">
      <div slot="title">
        <h1>
          <a-icon class="gradient" type="folder-open" />
          Categorias do sistema
        </h1>
      </div>
      <div slot="extra">
        <a-button type="primary" ghost @click="openCreateCategory = true">
          NOVA CATEGORIA
        </a-button>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mt-0 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template #header> <aIcon type="filter" /> FILTRAR </template>
        <a-row :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Nome </label>
              <a-input
                placeholder="Escreva aqui..."
                v-model="filters.searchTerm"
                @change="getCategories()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="listCategories"
      :loading="loadingCategories"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar v-else style="color: #f56a00; background-color: #fde3cf">
              {{ record.user.first_name }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>
      <div slot="module" slot-scope="record">
        {{ formatHayaModules(record.module) }}
      </div>

      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="edit(record)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top" title="Listar itens">
            <a
              class="edit ml-15 cgreen"
              @click="view(record.id)"
              style="font-size: 20px"
            >
              <a-icon type="unordered-list" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="pagination.page"
        :total="pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      />
    </div>

    <a-drawer
      title="NOVA CATEGORIA"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateCategory"
      @close="openCreateCategory = false"
    >
      <a-form v-if="openCreateCategory" :form="form" @submit="handleSubmit">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label :class="form.getFieldValue(`name`) ? 'filled' : ''">
                Nome da categoria
              </label>

              <a-input
                class="travel-input"
                placeholder="   Nome da categoria"
                v-decorator="[
                  `name`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label :class="form.getFieldValue(`module`) ? 'filled' : ''">
                Módulo
              </label>
              <a-select
                class="travel-input"
                placeholder="Selecione um módulo"
                optionFilterProp="txt"
                v-decorator="[
                  `module`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of modules"
                  :key="index"
                  :value="item.value"
                  :txt="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label :class="form.getFieldValue(`status`) ? 'filled' : ''">
                Status
              </label>
              <a-select
                class="travel-input"
                placeholder="Status"
                optionFilterProp="txt"
                v-decorator="[
                  `status`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of status"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item class="travel-input-outer">
              <label
                :class="form.getFieldValue(`description`) ? 'filled' : 'filled'"
                style="top: -46px"
                >Descrição</label
              >
              <a-input
                class="travel-textarea"
                placeholder="Escreva uma  descrição para a categoria..."
                type="textarea"
                v-decorator="[`description`]"
              >
              </a-input>
            </a-form-item>
          </a-col>

          <a-col class="a-center mt-20" :span="24">
            <a-button
              size="large"
              type="primary"
              :loading="loadingCreateCategory"
              html-type="submit"
            >
              CRIAR CATEGORIA
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-drawer>

    <a-drawer
      :title="false"
      placement="right"
      width="1024px"
      :closable="true"
      :visible="openCategoryEntries"
      @close="openCategoryEntries = false"
    >
      <list-category-entries
        v-if="openCategoryEntries"
        :idCategory="idCategory"
      />
    </a-drawer>

    <a-drawer
      title="EDITAR CATEGORIA"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openEditCategory"
      @close="openEditCategory = false"
    >
      <a-form
        v-if="openEditCategory"
        :form="form"
        @submit="submitUpdateCategory"
      >
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                :class="
                  form.getFieldValue(`name`) || theCategoty.name ? 'filled' : ''
                "
              >
                Nome da categoria
              </label>

              <a-input
                class="travel-input"
                placeholder="   Nome da categoria"
                v-decorator="[
                  `name`,
                  {
                    initialValue: theCategoty.name,
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                :class="
                  form.getFieldValue(`module`) || theCategoty.module
                    ? 'filled'
                    : ''
                "
              >
                Módulo
              </label>
              <a-select
                class="travel-input"
                placeholder="Selecione um módulo"
                optionFilterProp="txt"
                v-decorator="[
                  `module`,
                  {
                    initialValue: theCategoty.module,
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of modules"
                  :key="index"
                  :value="item.value"
                  :txt="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                :class="
                  form.getFieldValue(`status`) || theCategoty.status
                    ? 'filled'
                    : ''
                "
              >
                Status
              </label>
              <a-select
                class="travel-input"
                placeholder="Status"
                optionFilterProp="txt"
                v-decorator="[
                  `status`,
                  {
                    initialValue: theCategoty.status,
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of status"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item class="travel-input-outer">
              <label
                :class="
                  form.getFieldValue(`description`) || theCategoty.description
                    ? 'filled'
                    : 'filled'
                "
                style="top: -46px"
                >Descrição</label
              >
              <a-input
                class="travel-textarea"
                placeholder="Escreva uma  descrição para a categoria..."
                type="textarea"
                v-decorator="[
                  `description`,
                  { initialValue: theCategoty.description },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>

          <a-col class="a-center mt-20" :span="24">
            <a-button
              size="large"
              type="primary"
              :loading="loadingCreateCategory"
              html-type="submit"
            >
              ATUALIZAR CATEGORIA
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-drawer>
  </div>
</template>

<script>
import modules from "@/json/hayaModules.json";
import ListCategoryEntries from "./ListCategoryEntries.vue";

export default {
  name: "ListCategories",
  components: { ListCategoryEntries },
  data() {
    return {
      form: this.$form.createForm(this),
      theCategoty: {},
      listCategories: [],
      modules,
      status: [
        { label: "Ativo", value: "Ativo" },
        { label: "Desativado", value: "Desativado" },
      ],
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          sorter: true,
          width: 100,
        },
        {
          title: "Nome",
          dataIndex: "name",
          key: "name",
          sorter: true,
          width: 250,
        },
        {
          title: "Módulo",
          width: 180,
          scopedSlots: {
            customRender: "module",
          },
        },
        {
          title: "Descrição",
          dataIndex: "description",
          key: "description",
          width: 250,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 120,
        },
        {
          title: "N° Itens",
          dataIndex: "entries",
          key: "entries",
          width: 100,
        },
        {
          title: "Data/Hora",
          dataIndex: "created",
          key: "created",
          sorter: true,
          width: 180,
        },
        {
          title: "",
          key: "user",
          width: 100,
          scopedSlots: {
            customRender: "user",
          },
        },
        {
          title: "",
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      loadingCategories: false,
      openCategoryEntries: false,
      openCreateCategory: false,
      openEditCategory: false,
      idCategory: 0,
      filters: {
        users: {
          list: [],
          selected: [],
        },
        period: {
          selected: [],
        },
        searchTerm: "",
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
    };
  },
  beforeMount() {
    this.getCategories();
  },
  filters: {},
  methods: {
    formatHayaModules(module) {
      let theModule = "";

      if (module) {
        let selectedModule = this.modules.filter((mod) => {
          return mod.value == module;
        });

        theModule = selectedModule[0].name;
      }

      return theModule;
    },
    submitUpdateCategory(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.theCategoty.id;
        values.user_id = this.theCategoty.user.id;

        if (!err) {
          this.loadingCreateCategory = true;
          this.$http
            .post("/category/update", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateCategory = false;
              this.openEditCategory = false;
              this.getCategories();
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateCategory = false;
              this.openEditCategory = false;
            });
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;

        if (!err) {
          this.loadingCreateCategory = true;

          this.$http
            .post("/category/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateCategory = false;
              this.openCreateCategory = false;
              this.getCategories();
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateCategory = false;
              this.openCreateCategory = false;
            });
        }
      });
    },
    listCategorys() {
      this.openCreateCategory = false;
      this.getCategories();
    },
    view(id) {
      this.openCategoryEntries = true;
      this.idCategory = id;
    },
    edit(category) {
      this.openEditCategory = true;
      this.theCategoty = category;
    },
    changePage(current) {
      this.pagination.page = current;
      this.getCategories();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;

      this.getCategories();
    },
    getCategories(samePage) {
      this.loadingCategories = true;

      let filters = "";

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/category/list?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.pagination.page = 1;
          }
          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;

          this.listCategories = data.data;
          this.loadingCategories = false;
        })
        .catch(({ response }) => {
          response;
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.listCategories = [];
          this.loadingCategories = false;
        });
    },
  },
};
</script>
